import "./gallery.scss";
import "./gallery-device.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import { Photo } from "../../types/shared";
import { getWidthScreen } from "../../utils/window";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import { useModal } from "../Modals/useModal";
import withModal from "../Modals/withModal";
import Counter from "../Slider/Counter/Counter";
import useSlider, { MoveBy } from "../Slider/useSlider";
import GalleryOpen from "./GalleryOpen/GalleryOpen";

type PropsGallery = PropsWithChildren<{ photosList: Photo[] }>;

const Gallery: React.FC<PropsGallery> = (props: PropsGallery) => {
  if (!props.photosList || props.photosList.length < 5)
    return <DoNotMountComponent sms={"Necesita mínimo 5 fotos para montar la galería"} />;

  const totalPhotos = props.photosList.length;
  const photoListFiftyA: Photo[] = props.photosList.slice(0, totalPhotos / 2);
  const photoListFiftyB: Photo[] = props.photosList.slice(totalPhotos / 2, totalPhotos);
  const sliderGallery = useRef() as React.MutableRefObject<HTMLDivElement>;
  const buttonControl = useRef() as React.MutableRefObject<HTMLDivElement>;
  const counterGallery = useRef() as React.MutableRefObject<HTMLDivElement>;
  const rowA = useRef() as React.MutableRefObject<HTMLDivElement>;
  const rowB = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { next, prev, isCanNext, isCanPrev, totalsPositions, currentPosition } = useSlider(
    sliderGallery,
    MoveBy.WINDOW
  );
  const [has_modal, toggleModal] = useModal();
  const [photoSelected, setPhotoSelected] = useState<number>(0);

  const isButtonFitInScreen = () => {
    if (rowB.current) {
      const selectedPhoto = rowB.current.children[2] as HTMLElement;
      const offsetRightButton = selectedPhoto.offsetLeft + 350;
      return offsetRightButton < getWidthScreen();
    }
    return false;
  };

  const isCounterFitInScreen = () => {
    if (rowA.current) {
      const selectedPhoto = rowA.current.children[1] as HTMLElement;
      return selectedPhoto.offsetLeft < getWidthScreen();
    }
    return false;
  };

  const setLeftControl = () => {
    if (!rowB.current) return;
    const classDevice = `m-gallery-control--device`;
    const selectedPhoto = rowB.current.children[2] as HTMLImageElement;
    if (isButtonFitInScreen()) {
      buttonControl.current.style.left = `${selectedPhoto.offsetLeft}px`;
      buttonControl.current.classList.remove(classDevice);
    } else {
      buttonControl.current.removeAttribute("style");
      buttonControl.current.classList.add(classDevice);
    }
  };

  const setLeftCounter = () => {
    if (!counterGallery.current) return;
    if (!isCounterFitInScreen()) {
      counterGallery.current.style.left = "unset";
    } else {
      const photoOne = rowA.current.children[0] as HTMLImageElement;
      const offsetRightPhotoOne = photoOne.clientWidth + photoOne.offsetLeft;
      const widthCounter = counterGallery.current.offsetWidth;
      counterGallery.current.style.right = "unset";
      counterGallery.current.style.left = `${offsetRightPhotoOne - widthCounter}px`;
    }
  };

  const setBottomControl = () => {
    if (!isButtonFitInScreen()) return;
    if (isCanPrev) {
      buttonControl.current.style.bottom = `-65px`;
    } else {
      buttonControl.current.style.bottom = `-15px`;
    }
  };

  useEffect(() => {
    setBottomControl();
    setLeftControl();
  }, [isCanPrev]);

  function onResize() {
    setIfControlsAreNeeded();
    setLeftControl();
    setLeftCounter();
    setBottomControl();
  }

  function onLoad() {
    setIfControlsAreNeeded();
    setLeftControl();
    setLeftCounter();
    setBottomControl();
  }

  function onLoadPhoto(is_last: boolean) {
    if (is_last) {
      onLoad();
    }
  }

  function setIfControlsAreNeeded() {
    if (!sliderGallery.current) return;
    const is_fitInWindow = sliderGallery.current.scrollWidth === sliderGallery.current.offsetWidth;
    if (is_fitInWindow) {
      buttonControl.current.style.display = "none";
      counterGallery.current.style.display = "none";
    } else {
      buttonControl.current.style.display = "flex";
      counterGallery.current.style.display = "block";
    }
  }

  useEffect(() => {
    onLoad();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const openGallery = (indexPhoto: number) => {
    setPhotoSelected(indexPhoto);
    toggleModal();
  };

  const GalleryOpenWithModal = withModal(
    GalleryOpen as React.FC<{ photosList: Photo[]; photoSelected: number }>,
    { photoSelected, photosList: props.photosList },
    {
      has_modal,
      toggleModal,
    }
  );

  return (
    <>
      {has_modal && <GalleryOpenWithModal />}
      <section
        className={"o-gallery"}
        aria-label={"Galería de fotografías"}>
        <div
          className={"c-gallery"}
          ref={sliderGallery}>
          <div
            ref={rowA}
            className="m-gallery__row">
            {photoListFiftyA.map((photo, index) => {
              return (
                <SanityImage
                  className={`a-gallery__photo a-gallery__photo--a ${index === 0 ? "a-gallery__photo--first-a" : ""}`}
                  key={index}
                  {...photo.image}
                  width={692}
                  height={379}
                  alt={`${photo.alt ? photo.alt : "Foto de galería"}`}
                  onClick={() => openGallery(index)}
                />
              );
            })}
            <span className={"a-gallery__fill"} />
          </div>
          <div
            ref={rowB}
            className="m-gallery__row m-gallery__row--b">
            {photoListFiftyB.map((photo, index) => {
              return (
                <SanityImage
                  className={`a-gallery__photo a-gallery__photo--b ${index === 0 ? "a-gallery__photo--first-b" : ""}`}
                  key={index}
                  {...photo.image}
                  width={692}
                  height={379}
                  alt={`${photo.alt ? photo.alt : "Foto de galería"}`}
                  onClick={() => openGallery(index + photoListFiftyA.length)}
                  onLoad={() => {
                    onLoadPhoto(index === photoListFiftyB.length - 1);
                  }}
                />
              );
            })}
            <span className={"a-gallery__fill"} />
          </div>
        </div>
        <div
          id={"gallery-counter"}
          ref={counterGallery}>
          <Counter
            currentPosition={currentPosition}
            totalPositions={totalsPositions}
          />
        </div>

        <div
          id={"gallery-control"}
          className={"m-gallery-control"}
          ref={buttonControl}>
          <button
            onClick={prev}
            disabled={!isCanPrev}
            aria-label={"desplazar a la izquierda la galería de fotografías"}
            className={`m-control m-control--outline-sea ${isCanPrev ? "" : "m-control--disable"}`}>
            {left_svg}
          </button>
          <button
            onClick={next}
            disabled={!isCanNext}
            aria-label={"desplazar a la derecha la galería de fotografías"}
            className={`m-controls-text ${isCanNext ? "" : "m-controls-text--disable"}`}>
            <span className={`a-gallery__text-control  ${isCanPrev ? "a-gallery__text-control--hidden" : ""}`}>
              {" "}
              Ver más imágenes{" "}
            </span>
            <span className={"m-control m-control--outline-sea"}>{right_svg}</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default Gallery;

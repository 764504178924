import "./hero-home.scss";

import * as React from "react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../../assets/svg/controls_elements";
import { Video } from "../../../types/shared";
import { getWidthScreen } from "../../../utils/window";
import IframeVimeo from "../../IframeViemo/IframeVimeo";
import { useModal } from "../../Modals/useModal";
import withModal from "../../Modals/withModal";
import Counter from "../../Slider/Counter/Counter";
import { squareBackground_svg, wave_svg } from "./elements_hero";
import SlideHeroHome from "./SlideHeroHome/SlideHeroHome";

interface PropsHeroHome extends PropsWithChildren<{ title: string; videoList: Video[] }> {
  title: string;
  videoList: Video[];
}

const HeroHome: (props: PropsHeroHome) => JSX.Element = (props: PropsHeroHome) => {
  const sliderHeroHome = useRef() as React.MutableRefObject<HTMLDivElement>;
  const controlsHeroHome = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [positionSlider, setPositionSlider] = useState<number>(1);
  const [is_scrolling, setIsScrolling] = useState(true);
  const [codeSelected, setCodeSelected] = useState<string>(props.videoList[0].code);
  const [has_modal, toggleModal] = useModal();

  function handlerIntersect(entries, _observer) {
    entries.forEach((entry, _index) => {
      if (entry.isIntersecting) {
        const idHtml: string = entries[_index].target.id;
        const splitIdHtml = idHtml.split("slide-hero-home__");
        const index: number = parseInt(splitIdHtml[1]);
        setPositionSlider(index + 1);
      }
    });
  }

  function createObserver() {
    const options = {
      root: sliderHeroHome.current,
      rootMargin: "0px",
      delay: 500,
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handlerIntersect, options);
    props.videoList.forEach((video, index) => {
      observer.observe(document.getElementById(`slide-hero-home__${index}`) as HTMLElement);
    });
  }

  const totalSlides = props.videoList.length;

  const slides_render: JSX.Element[] = [];

  const prev = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider < 2) {
      setPositionSlider(totalSlides);
    } else {
      setPositionSlider(positionSlider - 1);
    }
    setIsScrolling(true);
  };

  const next = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider >= totalSlides) {
      setPositionSlider(1);
    } else {
      setPositionSlider(positionSlider + 1);
    }
    setIsScrolling(true);
  };

  useEffect(() => {
    sliderHeroHome.current.style.scrollSnapType = "unset";
    sliderHeroHome.current.scrollLeft = (positionSlider - 1) * getWidthScreen();
    setZIndexControls1();
  }, [positionSlider]);

  function setMandatoryX() {
    sliderHeroHome.current.style.scrollSnapType = "x mandatory";
  }
  function setPositionInOne() {
    setPositionSlider(1);
  }
  function setZIndexControls1() {
    document.documentElement.style.setProperty("--z-index-control", "2");
  }

  function setTopButtonsControl() {
    //   document.body.style.setProperty("--top-controls-hero-home", `${photoSlide.current.offsetTop}px`);
    const firstPhotoSlide: HTMLImageElement = document.getElementsByClassName(
      "a-slide-hero-home__photo"
    )[0] as HTMLImageElement;
    const heightControls = controlsHeroHome.current.clientHeight;
    document.body.style.setProperty("--top-controls-hero-home", `${firstPhotoSlide.offsetTop - heightControls}px`);
  }

  function onResize() {
    setPositionInOne();
    setTopButtonsControl();
  }

  useEffect(() => {
    createObserver();
    const firstPhotoSlide: HTMLImageElement = document.getElementsByClassName(
      "a-slide-hero-home__photo"
    )[0] as HTMLImageElement;
    sliderHeroHome.current.addEventListener("touchmove", setMandatoryX, { passive: true });
    window.addEventListener("resize", onResize);
    firstPhotoSlide.addEventListener("load", setTopButtonsControl);
    return () => {
      if (sliderHeroHome.current) {
        sliderHeroHome.current.removeEventListener("touchmove", setMandatoryX);
      }
      if (firstPhotoSlide) {
        firstPhotoSlide.removeEventListener("load", setTopButtonsControl);
      }
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const toggleModalVideo = (code: string) => {
    setCodeSelected(code);
    toggleModal();
  };

  props.videoList.forEach((video, index) => {
    slides_render.push(
      <div
        key={index}
        id={`slide-hero-home__${index}`}>
        <SlideHeroHome
          video={video}
          toggleModal={toggleModalVideo}
        />
      </div>
    );
  });

  const IframeVimeoWithModal = withModal(
    IframeVimeo as React.FC<any>,
    { code: codeSelected },
    {
      has_modal,
      toggleModal,
    }
  );

  return (
    <>
      {has_modal && <IframeVimeoWithModal />}
      <div className={"is-stacking-context"}>
        <div className="m-hero-home__elements">
          {squareBackground_svg}
          {wave_svg}
        </div>
        <section className={"o-hero-home"}>
          <div className="c-hero-home">
            <h1 className={"a-hero-home__title"}>{props.title}</h1>
            <Counter
              currentPosition={positionSlider}
              totalPositions={totalSlides}
              is_vertical={true}
            />
          </div>
          <div
            ref={controlsHeroHome}
            className="m-hero-home__controls">
            <button
              aria-label={"Deslizar al elemento siguiente"}
              className={"m-control  m-control--right"}
              onClick={next}>
              {right_svg}
            </button>
            <button
              aria-label={"Deslizar al elemento previo"}
              className={"m-control m-control--left"}
              onClick={prev}>
              {left_svg}
            </button>
          </div>
          <div
            className="scroll-container__native-hero-home m-hero-home__slider"
            ref={sliderHeroHome}>
            {slides_render}
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroHome;

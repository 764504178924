import "./slide-hero-home.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Video } from "../../../../types/shared";
import { play_svg } from "../elements_hero";

type VideoAndToggleModal = {
  video: Video;
  toggleModal: (code: string) => void;
};

type PropsSlideHeroHome = PropsWithChildren<VideoAndToggleModal>;

const SlideHeroHome: (props: PropsSlideHeroHome) => JSX.Element = (
  props: PropsSlideHeroHome
) => {
  return (
    <div className={"m-slide-hero-home"}>
      <SanityImage
        className={"a-slide-hero-home__photo"}
        {...props.video.image}
        alt={props.video.alt}
        width={1615}
        height={864}
      />
      {props.video.code && props.video.code !== "0" && (
        <button
          className="m-slide-hero-home__card"
          title={"Reproducir vídeo"}
          onClick={() => props.toggleModal(props.video.code)}
        >
          <div className="m-slide-hero-home__thumbnail">
            {play_svg}
            <SanityImage
              className={"a-slide-hero-home__thumbnail"}
              {...props.video.image}
              alt={props.video.alt}
              width={93}
              height={62}
            />
          </div>
          Reproducir vídeo
        </button>
      )}
    </div>
  );
};

export default SlideHeroHome;

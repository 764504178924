import "./modal-form-info.scss";

import * as React from "react";

import { InfoForm } from "../Forms/Info/InfoForm";
import { TypeForm } from "../Forms/useForm";
import withForm from "../Forms/withForm";
import { useModal } from "../Modals/useModal";
import withModal from "../Modals/withModal";

const ModalFormInfo: () => {
  ModalForm: React.FC;
  toggleModal: () => void;
} = () => {
  const [has_modal, toggleModal] = useModal();
  const InfoFormUse = withForm(InfoForm, TypeForm.CONTACT, "Modal");
  const FrameContact = () => {
    return (
      <div className={"c-we-talk"}>
        <div className="m-we-talk__form">
          <div className="m-we-talk__header">
            <p className={"a-title"}>¿Hablamos?</p>
            <p className={"m-we-talk__subtitle"}>Contacta con nosotros</p>
          </div>
          <InfoFormUse />
        </div>
      </div>
    );
  };
  const FormModal = withModal(FrameContact as React.FC, {}, { has_modal, toggleModal });
  return { ModalForm: FormModal as unknown as React.FC, toggleModal };
};

export default ModalFormInfo;

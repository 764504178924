import "../styles/layouts/home.scss";
import "../styles/layouts/home-elements.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { waveGallery_svg, wavePAQs_svg } from "../assets/svg/home_elements";
import BannerDownloadApp from "../components/BannerDownladApp/BannerDownloadApp";
import BrandsFoodServices from "../components/Brands/BrandsFoodServices";
import Gallery from "../components/Gallery/Gallery";
import Goals from "../components/Goals/Goals";
import HeroHome from "../components/Hero/HeroHome/HeroHome";
import Layout from "../components/Layout/layout";
import LatestPosts from "../components/News/LatestPosts/LatestPosts";
import SectorsResume from "../components/SectorsResume/SectorsResume";
import HomeMarkupSchema from "../components/SEO/HomeMarkupSchema";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import Summary from "../components/Summary/Summary";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Photo, Video } from "../types/shared";

const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  if (!data.sanityPageHome) {
    return (
      <Layout>
        <code>No hay datos en Sanity</code>
      </Layout>
    );
  }
  const videoList = data.sanityPageHome.hero.videoList as Video[];
  const photosList = data.sanityPageHome.gallery as Photo[];

  return (
    <Layout>
      <div className="l-home">
        <HeroHome
          title={data.sanityPageHome.hero.title}
          videoList={videoList}
        />
        <SectorsResume />
        <Goals />
        <BrandsFoodServices />
        <Summary />
        <Gallery photosList={photosList} />
        <div className="waveGallery_svg">{waveGallery_svg}</div>
        <BannerDownloadApp />
        <LatestPosts />
        <WeKnowYourProblems
          title={data.sanityPageHome.weKnowYourProblems?.title}
          problemsList={data.sanityPageHome.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <div className="is-hidden-overflow-x">
          <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        </div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head = ({ data }: PageProps<Queries.IndexPageQuery>) => (
  <>
    <SEO
      title={data.sanityPageHome?.seo.title}
      robots={data.sanityPageHome?.seo.seo_robots}
      description={data.sanityPageHome?.seo.description}
    />
    <HomeMarkupSchema problemList={data.sanityPageHome?.weKnowYourProblems?.PAQsList as Problem[]} />
  </>
);

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        description
      }
    }
    sanityPageHome {
      seo {
        title
        seo_robots
        description
      }
      hero {
        title
        videoList {
          alt
          code
          image {
            asset {
              _id
              gatsbyImageData
            }
          }
        }
      }
      summaryOfUsCtaText
      gallery {
        alt
        image {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
    }
  }
`;

import "./goal.scss";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import Queries from "../../../gatsby-types";

export interface GoalItem extends Queries.SanityGoal {
  icon: any;
  title: string;
  _rawResume: any;
  index: number;
}

interface PropsGoal extends PropsWithChildren<{ goal: GoalItem }> {
  is_active: boolean;
  index: number;
  setPositionSlider: any;
}

const Goal: (props: PropsGoal) => JSX.Element = (props: PropsGoal) => {
  return (
    <div
      onClick={() => {
        props.setPositionSlider(props.index + 1);
      }}
      id={`slide-goal__${props.index}`}
      className={`m-goal ${props.is_active ? "m-goal--active" : ""}`}>
      <SanityImage
        {...props.goal.icon.image}
        className={"a-goal__icon"}
        style={{ maxWidth: "35px" }}
        alt={`icono de ${props.goal.icon.alt}`}
        width={35}
        height={35}
      />
      <h3 className={"a-goal__title"}>{props.goal.title}</h3>
      <BlockContent blocks={props.goal._rawResume} />
    </div>
  );
};

export default Goal;

import "./latest-posts.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { useRef } from "react";

import { left_svg, right_svg } from "../../../assets/svg/controls_elements";
import useSlider, { MoveBy } from "../../Slider/useSlider";
import PostCard from "../PostCard/PostCard";

const LatestPosts: React.FC = () => {
  const { data, posts } = useStaticQuery(graphql`
    query LatestPost {
      data: sanityPageHome {
        textCta: latestBlogPostCtaText
        title: latestBlogPostTitle
      }
      posts: allSanityTemplatePost(limit: 3, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          _id
          publishedAt
          seo {
            description
          }

          slug {
            current
          }
          category {
            name
          }
          text: _rawContent
          hero {
            photo {
              alt
              image {
                asset {
                  _id
                  gatsbyImageData
                }
              }
            }
            title
          }
        }
      }
    }
  `);
  const sliderPost = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { next, isCanNext, prev, isCanPrev } = useSlider(sliderPost, MoveBy.ITEMS);
  while (posts.nodes.length < 3) {
    // TODO eliminar en cuando haya 3 posts
    posts.nodes.push({ ...posts.nodes[0] });
  }

  const divsFake: JSX.Element[] = [];

  for (let i = 1; i < 4; i++) {
    divsFake.push(
      <div
        key={i}
        className={"m-latest-posts__fake"}
      />
    );
  }

  return (
    <section className={"o-latest-posts"}>
      <div className={"c-latest-posts"}>
        <h2 className={"a-title a-latest-posts--title"}>{data.title}</h2>
        <div
          className="m-latest-posts__cards"
          ref={sliderPost}>
          {posts.nodes.map((post, index) => {
            return (
              <PostCard
                key={`${post._id}-${index}`}
                _id={post._id}
                title={post.hero.title}
                slug={post.slug}
                category={post.category?.name}
                resume={post.seo.description}
                date={post.publishedAt}
                thumbnail={post.hero.photo}
              />
            );
          })}
          {divsFake}
        </div>
        <div className="m-latest-post__button">
          <Link
            to={`/noticias/`}
            className={"m-button"}>
            {data.textCta} {right_svg}
          </Link>
          <div className="m-latest-post__controls">
            <button
              disabled={!isCanPrev}
              className="m-control"
              aria-label={"Deslizar a la izquierda para ver más noticias"}
              onClick={prev}>
              {left_svg}
            </button>
            <button
              disabled={!isCanNext}
              className="m-control"
              aria-label={"Deslizar a la derecha para ver más noticias"}
              onClick={next}>
              {right_svg}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestPosts;

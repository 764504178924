import * as React from "react";
// Remember that all const are suffix _svg

const play_svg = (
  <svg
    id={"hero-play"}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    role={"img"}
    viewBox="0 0 34 34"
  >
    <path
      data-name="Trazado 164"
      d="M29.021,4.979A17,17,0,0,0,4.979,29.021,17,17,0,0,0,29.021,4.979ZM17,31.9A14.9,14.9,0,1,1,31.9,17,14.914,14.914,0,0,1,17,31.9Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      data-name="Trazado 165"
      d="M181.062,161.615l11.418-6.595-11.418-6.595Z"
      transform="translate(-168.369 -138.02)"
      fill="#fff"
    />
  </svg>
);

const squareBackground_svg = (
  <svg
    id={"hero-home-squareBackground"}
    xmlns="http://www.w3.org/2000/svg"
    width="960"
    height="1077"
    viewBox="0 0 960 1077"
  >
    <path
      id="Rectángulo_68"
      data-name="Rectángulo 68"
      d="M150,0H960a0,0,0,0,1,0,0V1077a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V150A150,150,0,0,1,150,0Z"
      fill="#f8f8fa"
    />
  </svg>
);

const wave_svg = (
  <svg
    id={"hero-home-wave"}
    xmlns="http://www.w3.org/2000/svg"
    width="910.094"
    height="918.382"
    viewBox="0 0 910.094 918.382"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectángulo_119"
          data-name="Rectángulo 119"
          width="748.497"
          height="759.875"
          fill="none"
          stroke="#59b1de"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Grupo_112"
      data-name="Grupo 112"
      transform="translate(183.83) rotate(14)"
      opacity="0.5"
    >
      <g id="Grupo_111" data-name="Grupo 111" clipPath="url(#clip-path)">
        <path
          id="Trazado_111"
          data-name="Trazado 111"
          d="M42.238,114.653c-13.806,18.793-21.869,39.993-19.284,63.579,5.057,46.178,50.694,80.415,54.794,126.688C83.564,370.6,5.085,417.365.684,483.157-2.425,529.6,34.381,571.235,77.4,589.013c48.461,20.027,107.494,2.6,151.248,28.9,51.194,30.782,49.29,116.445,106.9,135.622,32.95,10.969,69.12-12.935,84.948-43.845s16.865-66.94,19.635-101.562c3.592-44.927,5.908-82.608,50.529-106.8,30.638-16.613,66.234-21.039,99.188-32.388A248.23,248.23,0,0,0,727.5,350.528c22.765-43.568,27.566-106.066-12.811-134.1-24.16-16.77-56.668-15.11-85.118-7.654-54.212,14.21-113.819,40.22-168.382,8.959-46.582-26.691-48.461-88.375-73.306-131.943-21.448-37.6-54.967-70.371-96.876-81.2-59.393-15.35-120.157,15.247-172.791,46.756C90.492,67.943,61.039,89.065,42.238,114.653Z"
          transform="translate(1.566 1.564)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          strokeDasharray="0 2"
        />
        <path
          id="Trazado_112"
          data-name="Trazado 112"
          d="M40.622,112.253c-12.992,17.831-20.651,37.908-18.516,60.181,2.964,31.183,24.961,56.9,38.816,85.019,5.553,11.258,9.8,22.9,10.978,35.435C77.738,355.01,5.323,399.8,5.509,461.423c.066,23.376,9.619,43.614,22.529,63.666,12.555,19.495,29.482,35.468,50.7,45.715,42.681,20.618,96.347,8.166,138.211,27.029q3.511,1.579,6.9,3.46c50.042,27.781,51.607,103.349,107.4,121.862,31.736,10.532,66.007-9.818,81.918-36.814s18.834-58.869,23.008-91c4.95-37.826,7.431-71.927,37.359-96.087a99.463,99.463,0,0,1,10.169-7.2c27.232-16.919,58.621-22.616,88.26-34.87C624.763,435.355,672.1,392.349,701,339.974c11.911-21.58,20.89-46.776,21.4-71.609.5-27.174-10.111-53.3-32.9-67.382-24.061-14.871-54.781-13-82.034-5.144-44.588,12.86-92.574,34.279-139.053,21.427a103.479,103.479,0,0,1-22.773-9.326C400.6,182.921,396.608,124.321,371.68,82.982c-21.266-35.253-53.568-65.751-93.627-75.8-56.854-14.26-115.029,14.2-165.182,44.3C86.371,67.388,58.495,87.672,40.622,112.253Z"
          transform="translate(17.235 10.604)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.001"
          strokeDasharray="0 2.001"
        />
        <path
          id="Trazado_113"
          data-name="Trazado 113"
          d="M39.3,109.854C27.115,126.727,19.87,145.677,21.55,166.642c2.324,29.407,22.03,53.972,34.642,80.688,5.057,10.68,8.967,21.7,10.152,33.528C72.207,339.421,6.386,382.477,10.622,439.69c1.759,23.722,12.427,42,24.086,63.753,10.949,20.44,24.746,37.487,45.657,49.154,39.493,22.03,91.426,12,132.249,28.974q3.418,1.424,6.738,3.1c48.894,24.779,53.927,90.254,107.9,108.1,30.526,10.1,62.9-6.7,78.884-29.775s20.812-50.8,26.385-80.444c6.643-34.915,9.4-68.364,35.617-92.5a94.652,94.652,0,0,1,8.905-7.279c23.83-17.228,51.02-24.2,77.34-37.355,46.966-23.475,91.476-66.122,120.408-115.987,13.054-22.5,24.23-47.994,25.436-74.19,1.189-28.3-11.2-56.313-35.617-69.7-23.85-13.071-52.895-10.891-78.95-2.634-42.639,13.525-88.1,35.468-133.174,23.875a98.661,98.661,0,0,1-22.1-8.629C386.883,174.8,380.8,119.284,355.77,80.178,334.7,47.266,303.6,19.043,265.393,9.766,211.074-3.4,155.5,22.92,107.82,51.617,82.536,66.839,56.238,86.28,39.3,109.854Z"
          transform="translate(32.616 19.645)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.001"
          strokeDasharray="0 2.003"
        />
        <path
          id="Trazado_114"
          data-name="Trazado 114"
          d="M38.2,107.454c-11.37,15.915-18.211,33.738-16.976,53.394C22.9,188.476,40.322,211.885,51.7,237.209c4.558,10.1,8.133,20.506,9.326,31.62,5.883,55-52.763,96.479-45.055,149.126C19.51,442.149,31.244,458.283,41.6,481.8c9.4,21.336,20,39.423,40.621,52.593,36.343,23.211,86.5,15.829,126.279,30.923q3.338,1.263,6.577,2.733c47.746,21.778,56.243,77.154,108.4,94.337,29.313,9.665,59.789-3.575,75.849-22.74s22.789-42.722,29.767-69.884c8.336-32.008,11.378-64.826,33.874-88.916a93.394,93.394,0,0,1,7.642-7.357c20.473-17.48,43.424-25.791,66.411-39.836,41.161-25.151,82.958-67.357,111.792-114.773,14.235-23.413,27.64-49.208,29.469-76.77,1.957-29.424-12.2-59.376-38.337-72.018-23.533-11.382-51-8.781-75.862-.124-40.695,14.19-83.631,36.657-127.3,26.315a93.343,93.343,0,0,1-21.427-7.927C373.4,166.686,365.233,114.245,340.09,77.373,319.237,46.8,289.322,20.854,252.962,12.357,201.182.286,148.2,24.462,103,51.752,78.936,66.288,54.215,84.892,38.2,107.454Z"
          transform="translate(47.768 28.684)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.002"
          strokeDasharray="0 2.004"
        />
        <path
          id="Trazado_115"
          data-name="Trazado 115"
          d="M37.3,105.055c-10.524,14.978-16.989,31.653-16.209,50,1.032,25.857,16.163,48.11,26.3,72.039,4.054,9.52,7.3,19.309,8.5,29.709,5.9,51.446-44.98,91.422-34.382,139.424,5.462,24.751,18.174,38.618,27.182,63.926,7.9,22.187,15.271,41.265,35.584,56.032,33.268,24.177,81.575,19.66,120.313,32.867q3.251,1.1,6.412,2.374C257.592,570.2,269.556,615.477,319.9,632c28.1,9.227,56.68-.458,72.815-15.7s24.709-34.667,33.144-59.327c9.962-29.127,13.356-61.317,32.132-85.328a96.59,96.59,0,0,1,6.383-7.44c17.088-17.777,35.831-27.4,55.483-42.313,35.373-26.856,74.549-68.538,103.176-113.559,15.453-24.309,30.989-50.426,33.507-79.354,2.655-30.547-13.133-62.51-41.058-74.334-23.144-9.8-49.121-6.672-72.782,2.386-38.746,14.854-79.156,37.85-121.416,28.763a89.034,89.034,0,0,1-20.758-7.225c-40.427-19.994-50.645-69.363-75.924-104-20.622-28.243-49.373-51.9-83.883-59.62C191.478,3.972,141.085,26.006,98.367,51.892,75.528,65.739,52.425,83.533,37.3,105.055Z"
          transform="translate(62.724 37.726)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.003"
          strokeDasharray="0 2.005"
        />
        <path
          id="Trazado_116"
          data-name="Trazado 116"
          d="M39.343,102.655c-9.71,14.02-15.771,29.568-15.441,46.6.388,24.078,13.232,45.178,22.125,67.708,3.559,8.942,6.469,18.112,7.671,27.8,5.929,47.887-36.649,86.36-23.71,129.722,7.559,25.353,21.13,36.884,28.73,64.013,6.453,23.017,10.581,43.015,30.547,59.467,30.287,24.957,76.65,23.5,114.348,34.816q3.158.947,6.251,2.015c45.443,15.775,60.871,50.958,109.4,66.812,26.889,8.794,53.572,2.667,69.78-8.67s26.678-26.6,36.525-48.766c11.647-26.228,15.317-57.845,30.39-81.745,1.606-2.547,3.266-5.07,5.119-7.514,13.7-18.083,28.243-29.028,44.559-44.8,29.585-28.6,66.226-69.661,94.551-112.341,16.716-25.184,34.337-51.64,37.545-81.935,3.356-31.674-14-65.738-43.779-76.65-22.694-8.319-47.23-4.566-69.693,4.9-36.8,15.515-74.685,39.039-115.541,31.2a84.1,84.1,0,0,1-20.081-6.523c-38.887-18.322-51.14-64.624-76.58-97.008-20.366-25.923-47.973-47.284-80.634-54.224-46.7-9.884-94.5,10.012-134.743,34.49C75.059,65.185,53.546,82.145,39.343,102.655Z"
          transform="translate(74.74 46.764)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.003"
          strokeDasharray="0 2.007"
        />
        <path
          id="Trazado_117"
          data-name="Trazado 117"
          d="M42.156,100.257c-8.893,13.063-14.549,27.488-14.669,43.205-.256,22.306,10.3,42.247,17.951,63.385a123.206,123.206,0,0,1,6.841,25.894c5.949,44.324-27.826,81.216-13.042,120.016,9.884,25.931,24.148,35.134,30.287,64.1,5.049,23.838,5.949,44.666,25.506,62.906,27.418,25.568,71.721,27.331,108.382,36.76,2.048.528,4.079,1.073,6.085,1.651,44.295,12.778,63.191,37.863,109.9,53.052,25.671,8.36,50.455,5.788,66.742-1.631s28.656-18.537,39.9-38.209c13.339-23.33,17.257-54.406,28.652-78.157,1.23-2.568,2.465-5.115,3.852-7.592,10.317-18.4,20.651-30.691,33.635-47.28,23.789-30.394,57.985-70.738,85.935-111.128,18.017-26.034,37.677-52.857,41.582-84.515,4.042-32.8-14.83-69.087-46.5-78.966-22.207-6.932-45.348-2.456-66.61,7.4-34.853,16.184-70.214,40.228-109.662,33.647a79.712,79.712,0,0,1-19.408-5.821c-37.351-16.646-51.611-59.9-77.236-90.018C280.194,45.343,253.71,26.29,222.9,20.126c-44.163-8.785-89.37,8.967-127.134,32.037C75.366,64.636,55.429,80.758,42.156,100.257Z"
          transform="translate(85.981 55.806)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.004"
          strokeDasharray="0 2.008"
        />
        <path
          id="Trazado_118"
          data-name="Trazado 118"
          d="M45.022,97.857c-8.084,12.1-13.3,25.4-13.9,39.811-.855,20.531,7.369,39.316,13.781,59.059a143.865,143.865,0,0,1,6.011,23.983c5.974,40.765-18.6,75.928-2.37,110.314,12.464,26.406,27.219,33.383,31.835,64.186,3.7,24.656,1.42,46.223,20.469,66.345,24.676,26.059,66.8,31.162,102.412,38.7,1.994.425,3.968.85,5.924,1.292,43.147,9.772,65.507,24.763,110.4,39.287,24.462,7.927,47.346,8.909,63.711,5.4s30.634-10.47,43.279-27.653c15.036-20.428,19.148-51,26.906-74.569.855-2.593,1.668-5.161,2.593-7.667a326.27,326.27,0,0,1,22.707-49.765c17.951-32.24,49.811-71.766,77.319-109.914,19.363-26.844,41.013-54.071,45.616-87.091,4.731-33.932-15.618-72.567-49.22-81.286-21.7-5.631-43.457-.347-63.526,9.913-32.9,16.848-65.738,41.421-103.783,36.1a75.537,75.537,0,0,1-18.735-5.123c-35.811-14.97-52.052-55.2-77.889-83.033C268.772,44.83,243.39,28.1,214.428,22.718,172.8,15.022,130.186,30.636,94.9,52.3,75.726,64.086,57.367,79.37,45.022,97.857Z"
          transform="translate(97.172 64.844)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.005"
          strokeDasharray="0 2.009"
        />
        <path
          id="Trazado_119"
          data-name="Trazado 119"
          d="M47.981,95.459C40.7,106.6,35.893,118.777,34.844,131.877c-1.5,18.756,4.438,36.381,9.611,54.728a191.418,191.418,0,0,1,5.181,22.075c6,37.21-9.074,70.424,8.3,100.616C73.3,335.979,88.28,340.933,91.323,373.565c2.378,25.481-2.989,47.693,15.432,69.784,22.075,26.46,61.87,34.993,96.446,40.654q2.9.471,5.763.929c41.991,6.771,67.823,11.663,110.9,25.527,23.248,7.489,44.237,12.026,60.677,12.439s32.619-2.4,46.656-17.1c16.741-17.521,20.989-47.606,25.167-70.982.467-2.618.863-5.206,1.329-7.749,3.5-19.057,5.375-34.085,11.783-52.242,12.039-34.118,41.682-72.753,68.7-108.7,20.758-27.62,44.291-55.3,49.658-89.672,5.355-35.072-16.4-76.2-51.945-83.6-21.188-4.409-41.574,1.759-60.441,12.423-30.956,17.513-61.267,42.61-97.9,38.536a71.613,71.613,0,0,1-18.062-4.422C321.2,126.1,303.013,88.87,276.933,63.348c-19.466-19.053-43.775-33.433-70.887-38.04-39.089-6.6-79.107,6.874-111.92,27.133C76.175,63.538,59.393,77.975,47.981,95.459Z"
          transform="translate(108.271 73.884)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.005"
          strokeDasharray="0 2.011"
        />
        <path
          id="Trazado_120"
          data-name="Trazado 120"
          d="M51.114,93.06a80.021,80.021,0,0,0-12.365,33.02c-2.184,16.981,1.507,33.453,5.437,50.405,1.556,6.622,3.134,13.323,4.36,20.168,6.015,33.647.636,64.64,18.971,90.91,18.6,26.645,33.511,29.907,34.931,64.36,1.09,26.315-7.246,49.084,10.4,73.219,19.6,26.815,56.945,38.825,90.481,42.6q2.811.316,5.594.566c40.848,3.774,70.139-1.428,111.4,11.766,22.034,7.056,41.128,15.152,57.642,19.474s34.609,5.681,50.038-6.535c18.446-14.611,22.761-44.2,23.421-67.394.074-2.646.058-5.252.07-7.828.07-19.383-2.324-35.786.855-54.724,6.044-35.992,33.606-73.694,60.082-107.486,22.211-28.342,47.631-56.519,53.687-92.252,6.052-36.2-17.158-80.015-54.662-85.923-20.68-3.253-39.683,3.873-57.353,14.933-29.011,18.178-56.792,43.8-92.029,40.984a68.039,68.039,0,0,1-17.389-3.72c-32.731-11.622-52.841-45.893-79.2-69.058C246.36,43.733,223.1,31.727,197.842,27.9,161.3,22.392,123.867,33.725,93.535,52.576,76.8,62.984,61.584,76.579,51.114,93.06Z"
          transform="translate(119.191 82.922)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.006"
          strokeDasharray="0 2.012"
        />
        <path
          id="Trazado_121"
          data-name="Trazado 121"
          d="M54.62,90.664A85.7,85.7,0,0,0,43.027,120.29c-2.832,15.2-1.428,30.522,1.263,46.078,1.057,6.044,2.3,12.125,3.53,18.256,6.04,30.089,10.4,58.522,29.643,81.208C99.65,292,114.182,294.027,113.947,330.279c-.178,27.17-11.329,50.418,5.355,76.654,17.278,27.17,52.019,42.66,84.515,44.547q2.725.161,5.429.206c39.7.772,72.46-14.528,111.9-2,20.824,6.622,38.02,18.273,54.608,26.513s36.616,13.789,53.415,4.021c20.176-11.659,24.486-40.765,21.683-63.81-.326-2.663-.751-5.293-1.2-7.9-3.369-19.7-10.057-37.466-10.069-57.209-.017-37.821,25.56-74.59,51.466-106.268,23.723-29.011,50.971-57.737,57.721-94.832,6.75-37.326-17.943-84.011-57.378-88.239-20.2-2.163-37.8,5.978-54.273,17.443-27.058,18.843-52.317,44.988-86.146,43.424a64.677,64.677,0,0,1-16.716-3.018c-31.2-9.95-53.188-41.31-79.854-62.072a133.211,133.211,0,0,0-64.393-27.248c-34.011-4.409-68.847,4.781-96.7,22.228C77.8,62.437,64.115,75.161,54.62,90.664Z"
          transform="translate(129.743 91.961)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.007"
          strokeDasharray="0 2.013"
        />
        <path
          id="Trazado_122"
          data-name="Trazado 122"
          d="M45.238,156.247c3.295,32.207,18.653,64.4,43.015,87.855,26.076,25.106,39.964,26.5,38.036,64.529-1.424,28.041-15.222,51.718.318,80.1,15.086,27.55,47.09,46.491,78.545,46.491,40.53,0,78.335-28.322,117.667-15.915,19.611,6.185,34.907,21.394,51.574,33.548s38.589,21.865,56.792,14.582c21.869-8.753,26.175-37.264,19.941-60.223s-19.85-44.373-23.45-67.675c-6.123-39.58,17.538-75.44,42.846-105.05s54.315-58.955,61.759-97.413-18.764-88.206-60.1-90.559c-52.234-2.973-79.313,66.321-131.456,65.821-37.5-.359-64.851-35.943-96.55-57.4C183.849,14.1,93.162,30.418,58.974,88.267,46.964,108.583,42.8,132.417,45.238,156.247Z"
          transform="translate(139.443 100.997)"
          fill="none"
          stroke="#59b1de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.007"
          strokeDasharray="0 2.015"
        />
      </g>
    </g>
  </svg>
);

export { play_svg, squareBackground_svg, wave_svg };

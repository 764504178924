import { Script, ScriptStrategy } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { toPlainText } from "../../utils/blocktext";
import { Problem } from "../WeKnowYourProblems/ProblemCard/ProblemCard";

type PropsHomeMarkupSchema = PropsWithChildren<{
  problemList: Problem[];
}>;

const HomeMarkupSchema: React.FC<PropsHomeMarkupSchema> = (props: PropsHomeMarkupSchema) => {
  const markup = `{ 
      "@context": "https://schema.org",
      "@type": "Corporation",
      "name": "Grupo Usieto",
      "alternateName": "D.I.R.E USIETO, S.A.",
      "url": "https://www.grupousieto.com",
      "logo": "https://www.grupousieto.com/static/logo.webp",
      "knowsAbout": {
        "@type": "FAQPage",
        "mainEntity": [
          ${props.problemList.map((problem) => {
            return `{
                      "@type": "Question",
                      "name": "${problem.question}",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${toPlainText(problem.answer)}"
                      }
                    }`;
          })}
        ]
      },
      "location": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Zaragoza",
          "addressRegion": "ESP",
          "postalCode": "50197",
          "streetAddress": "Polígono Plaza C/ PERTUSA"}
          },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "976 470 287",
        "contactType": "customer service",
        "contactOption": "TollFree",
        "areaServed": "ES",
        "availableLanguage": "es"
      },
      "sameAs": [
        "https://www.linkedin.com/company/grupo-usieto-log%C3%ADstica-y-distribuci%C3%B3n-comercial/",
        "https://www.youtube.com/channel/UCDvm-f5nNKSlYyyTqqna4gA",
        "https://www.grupousieto.com/contacto/"
      ]
  	}`;
  return (
    <>
      <Script
        strategy={ScriptStrategy.postHydrate}
        type="application/ld+json">
        {markup}
      </Script>
    </>
  );
};

export default HomeMarkupSchema;

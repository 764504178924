import "./sector-resume.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { right_svg } from "../../../assets/svg/controls_elements";
import { Photo, Slug } from "../../../types/shared";
import { gotoOnClick } from "../../../utils/window";
import DoNotMountComponent from "../../DoNotMountComponent/DoNotMountComponent";

export type LinkSanity = {
  slug: Slug | null | undefined;
  name: string | null | undefined;
};

type PropsSectorResume = PropsWithChildren<{
  title: string;
  slug: string;
  index: number;
  photo: Photo | null | undefined;
  linkServicesList: LinkSanity[];
  is_blueBackground?: boolean;
}>;

const SectorResume: (props: PropsSectorResume) => JSX.Element = (props: PropsSectorResume) => {
  if (!props.photo?.image?.asset)
    return <DoNotMountComponent sms={"Falta una imagen para montar el resumen del sector"} />;

  function getAbsoluteSlug(slug: Slug): string {
    if (slug.current && slug.current?.split("/").length > 1) {
      return `${slug.current}/`;
    }
    return `/${props.slug}/${slug.current}/`;
  }

  return (
    <div
      id={`slider-resume__${props.index}`}
      className={`m-sector-resume ${props.is_blueBackground ? "m-sector-resume--background-blue" : ""}`}>
      <div className="m-sector-resume__photo">
        <SanityImage
          className={"a-sector-resume__photo"}
          {...props.photo.image}
          width={500}
          height={300}
          alt={`${props.photo.alt}`}
        />
      </div>

      <div className="m-sector-resume__info">
        <div className="m-sector-resume__title">
          <h2 className={"a-sector-resume__title"}>
            <Link to={`/${props.slug}/`}> {props.title}</Link>
          </h2>
          <p className={`m-sector-resume__numeration`}>
            0{props.index} <sup className={"a-sector-resume__denominator"}>/ 02</sup>
          </p>
        </div>
        <ul className={"m-sector-resume__list"}>
          {props.linkServicesList.map((link, index) => (
            <li key={index}>
              {link.slug?.current && (
                <Link
                  className={"m-sector-resume__item"}
                  to={getAbsoluteSlug(link.slug)}>
                  {link.name} {right_svg}
                </Link>
              )}
            </li>
          ))}
          {props.is_blueBackground && (
            <li>
              <span
                role={"link"}
                onClick={() => {
                  gotoOnClick(process.env.GATSBY_LINK_LOGIN_CLIENTS);
                }}
                className={"m-sector-resume__item"}>
                Acceso clientes {right_svg}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SectorResume;

import * as React from "react";
// Remember that all const are suffix _svg

const wave_svg = (
  <svg
    id="Grupo_123"
    data-name="Grupo 123"
    xmlns="http://www.w3.org/2000/svg"
    width="315.536"
    height="307.748"
    viewBox="0 0 315.536 307.748">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectángulo_119"
          data-name="Rectángulo 119"
          width="315.536"
          height="307.748"
          fill="none"
          stroke="#59b1de"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Grupo_111"
      data-name="Grupo 111"
      clipPath="url(#clip-path)">
      <path
        id="Trazado_111"
        data-name="Trazado 111"
        d="M18.1,46.732c-5.82,7.611-9.219,16.2-8.129,25.749,2.132,18.7,21.371,32.568,23.1,51.308,2.452,26.6-30.631,45.54-32.487,72.186-1.311,18.811,14.205,35.671,32.34,42.871,20.429,8.111,45.315,1.052,63.76,11.706,21.581,12.467,20.779,47.16,45.066,54.927,13.89,4.443,29.138-5.238,35.811-17.757s7.11-27.111,8.277-41.132c1.514-18.2,2.491-33.456,21.3-43.254,12.916-6.728,27.922-8.521,41.814-13.117,24.712-8.175,45.923-25.7,58.026-47.958,9.6-17.645,11.621-42.956-5.4-54.31-10.185-6.792-23.889-6.12-35.882-3.1-22.853,5.755-47.982,16.289-70.983,3.628-19.637-10.81-20.429-35.792-30.9-53.437-9.041-15.229-23.172-28.5-40.839-32.887C97.928-4.061,72.313,8.33,50.124,21.091,38.437,27.814,26.021,36.369,18.1,46.732Z"
        transform="translate(0.371 0.336)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Trazado_112"
        data-name="Trazado 112"
        d="M20.311,47.479c-5.477,7.222-8.706,15.353-7.806,24.373,1.25,12.629,10.523,23.046,16.363,34.432A39.983,39.983,0,0,1,33.5,120.636c2.461,25.159-28.066,43.3-27.988,68.256.028,9.467,4.055,17.663,9.5,25.785a52.011,52.011,0,0,0,21.374,18.515C54.373,241.541,77,236.5,94.645,244.138q1.48.64,2.91,1.4c21.1,11.251,21.755,41.856,45.277,49.354,13.379,4.265,27.826-3.976,34.533-14.91s7.94-23.842,9.7-36.855c2.087-15.319,3.133-29.13,15.749-38.915A41.964,41.964,0,0,1,207.1,201.3c11.48-6.852,24.712-9.159,37.207-14.122,22.255-8.838,42.21-26.256,54.393-47.468,5.021-8.74,8.807-18.944,9.021-29,.209-11.005-4.262-21.588-13.868-27.289-10.143-6.023-23.094-5.265-34.582-2.083-18.8,5.208-39.025,13.883-58.619,8.678a44.649,44.649,0,0,1-9.6-3.777c-18.99-10.133-20.671-33.866-31.18-50.608C150.907,21.347,137.29,9,120.4,4.924,96.436-.852,71.911,10.674,50.769,22.866,39.6,29.309,27.846,37.524,20.311,47.479Z"
        transform="translate(4.079 2.278)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Trazado_113"
        data-name="Trazado 113"
        d="M22.6,48.227c-5.136,6.834-8.19,14.508-7.482,23,.98,11.91,9.287,21.859,14.6,32.678A40.15,40.15,0,0,1,34,117.483C36.47,141.2,8.723,158.639,10.508,181.81c.741,9.608,5.239,17.008,10.154,25.82,4.616,8.278,10.432,15.182,19.247,19.907,16.649,8.922,38.542,4.859,55.751,11.734q1.441.577,2.84,1.254c20.612,10.036,22.733,36.553,45.488,43.781,12.869,4.09,26.515-2.712,33.254-12.059s8.773-20.573,11.123-32.58c2.8-14.14,3.965-27.687,15.015-37.464a39.621,39.621,0,0,1,3.754-2.948c10.046-6.977,21.508-9.8,32.6-15.129,19.8-9.507,38.562-26.779,50.759-46.974,5.5-9.113,10.215-19.437,10.723-30.047.5-11.462-4.72-22.807-15.015-28.229-10.054-5.294-22.3-4.411-33.282-1.067-17.975,5.478-37.141,14.365-56.141,9.669a42.627,42.627,0,0,1-9.318-3.495c-18.339-9.454-20.9-31.939-31.455-47.777-8.881-13.33-21.992-24.76-38.1-28.517-22.9-5.33-46.328,5.327-66.427,16.95C40.825,30.806,29.738,38.68,22.6,48.227Z"
        transform="translate(7.719 4.22)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Trazado_114"
        data-name="Trazado 114"
        d="M24.937,48.974C20.144,55.42,17.26,62.638,17.781,70.6c.707,11.189,8.049,20.67,12.844,30.926a40.785,40.785,0,0,1,3.932,12.806c2.48,22.275-22.243,39.074-18.993,60.4,1.493,9.8,6.44,16.333,10.806,25.855,3.961,8.641,8.432,15.966,17.124,21.3,15.321,9.4,36.465,6.411,53.234,12.524q1.407.512,2.772,1.107c20.128,8.82,23.71,31.247,45.7,38.206,12.357,3.914,25.2-1.448,31.975-9.21s9.607-17.3,12.548-28.3c3.514-12.963,4.8-26.254,14.28-36.011a38.721,38.721,0,0,1,3.222-2.98c8.631-7.079,18.306-10.445,28-16.134C252.572,170.9,270.192,153.8,282.347,134.6c6-9.482,11.652-19.929,12.423-31.092.825-11.917-5.141-24.047-16.162-29.167-9.92-4.61-21.5-3.556-31.98-.05-17.155,5.747-35.256,14.846-53.664,10.658a40.383,40.383,0,0,1-9.033-3.21C166.24,72.963,162.8,51.724,152.2,36.791c-8.791-12.383-21.4-22.89-36.73-26.331-21.828-4.889-44.165,4.9-63.219,15.955C42.108,32.3,31.687,39.836,24.937,48.974Z"
        transform="translate(11.305 6.162)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Trazado_115"
        data-name="Trazado 115"
        d="M27.322,49.722c-4.436,6.066-7.162,12.82-6.833,20.248.435,10.472,6.814,19.484,11.086,29.175a42.064,42.064,0,0,1,3.582,12.032C37.646,132.013,16.2,148.2,20.663,167.645c2.3,10.024,7.661,15.64,11.459,25.89,3.329,8.986,6.438,16.712,15,22.693,14.024,9.791,34.389,7.962,50.719,13.311q1.371.446,2.7.961c19.642,7.6,24.686,25.942,45.909,32.633a32.411,32.411,0,0,0,30.7-6.36c6.8-6.176,10.416-14.04,13.972-24.027,4.2-11.8,5.63-24.833,13.546-34.558a39.6,39.6,0,0,1,2.691-3.013c7.2-7.2,15.105-11.1,23.389-17.137,14.912-10.877,31.427-27.758,43.495-45.991,6.514-9.845,13.064-20.422,14.125-32.138,1.119-12.371-5.536-25.316-17.308-30.105-9.757-3.968-20.708-2.7-30.682.966-16.334,6.016-33.369,15.329-51.184,11.649a38.574,38.574,0,0,1-8.751-2.926C163.4,71.4,159.093,51.4,148.436,37.374c-8.693-11.438-20.814-21.019-35.362-24.146-20.758-4.444-42,4.479-60.01,14.963C43.437,33.8,33.7,41.006,27.322,49.722Z"
        transform="translate(14.845 8.104)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Trazado_116"
        data-name="Trazado 116"
        d="M30.4,50.469c-4.093,5.678-6.648,11.975-6.509,18.874.164,9.751,5.578,18.3,9.327,27.422a44.235,44.235,0,0,1,3.234,11.26c2.5,19.394-15.45,34.976-10,52.537,3.187,10.268,8.907,14.938,12.112,25.925,2.72,9.322,4.461,17.421,12.877,24.084,12.768,10.107,32.313,9.516,48.2,14.1q1.331.384,2.635.816c19.157,6.389,25.661,20.638,46.118,27.059,11.335,3.561,22.584,1.08,29.417-3.511s11.247-10.773,15.4-19.75c4.91-10.623,6.457-23.427,12.811-33.106.677-1.032,1.377-2.053,2.158-3.043,5.776-7.324,11.906-11.756,18.784-18.143,12.472-11.582,27.918-28.212,39.859-45.5,7.047-10.2,14.475-20.914,15.827-33.183,1.415-12.828-5.9-26.624-18.455-31.043-9.567-3.369-19.91-1.849-29.38,1.983-15.512,6.284-31.484,15.811-48.707,12.637a36.486,36.486,0,0,1-8.465-2.642c-16.393-7.421-21.559-26.173-32.283-39.288-8.586-10.5-20.224-19.15-33.992-21.961-19.688-4-39.838,4.055-56.8,13.968C45.461,35.294,36.392,42.162,30.4,50.469Z"
        transform="translate(17.689 10.046)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Trazado_117"
        data-name="Trazado 117"
        d="M33.669,51.217c-3.749,5.29-6.133,11.132-6.184,17.5-.108,9.034,4.341,17.11,7.567,25.671a48.341,48.341,0,0,1,2.884,10.487c2.508,17.951-11.73,32.892-5.5,48.606,4.167,10.5,10.18,14.229,12.768,25.96,2.129,9.654,2.508,18.09,10.752,25.477,11.558,10.355,30.235,11.069,45.689,14.888.863.214,1.72.435,2.565.669,18.673,5.175,26.639,15.334,46.33,21.486,10.822,3.386,21.27,2.344,28.136-.66s12.08-7.507,16.821-15.475c5.623-9.449,7.275-22.034,12.078-31.654.519-1.04,1.039-2.072,1.624-3.075,4.349-7.452,8.706-12.43,14.179-19.148,10.028-12.31,24.444-28.649,36.227-45.006,7.6-10.544,15.883-21.407,17.529-34.228,1.7-13.284-6.252-27.98-19.6-31.981-9.362-2.807-19.117-.995-28.08,3-14.693,6.554-29.6,16.292-46.229,13.627A34.637,34.637,0,0,1,175.044,75c-15.746-6.742-21.757-24.26-32.56-36.457-8.469-9.564-19.634-17.281-32.622-19.777C91.245,15.206,72.187,22.4,56.268,31.74,47.668,36.791,39.264,43.32,33.669,51.217Z"
        transform="translate(20.349 11.988)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Trazado_118"
        data-name="Trazado 118"
        d="M36.946,51.964a30.484,30.484,0,0,0-5.86,16.124c-.36,8.315,3.107,15.923,5.81,23.919a56.4,56.4,0,0,1,2.534,9.713c2.518,16.51-7.842,30.751-1,44.677,5.254,10.694,11.475,13.52,13.42,26,1.558,9.985.6,18.72,8.629,26.87,10.4,10.554,28.158,12.621,43.173,15.675.841.172,1.673.344,2.5.523,18.189,3.958,27.615,10.029,46.541,15.911,10.312,3.21,19.959,3.608,26.858,2.189s12.914-4.24,18.245-11.2c6.339-8.273,8.072-20.653,11.342-30.2.36-1.05.7-2.09,1.093-3.1A129.707,129.707,0,0,1,219.8,168.9c7.567-13.057,21-29.065,32.595-44.515,8.163-10.872,17.289-21.9,19.23-35.272,1.994-13.743-6.584-29.39-20.749-32.921-9.148-2.281-18.32-.14-26.78,4.015-13.871,6.824-27.713,16.776-43.751,14.619a32.87,32.87,0,0,1-7.9-2.075c-15.1-6.063-21.943-22.355-32.835-33.628-8.344-8.634-19.044-15.41-31.253-17.59C90.812,18.416,72.847,24.74,57.974,33.515,49.89,38.287,42.15,44.477,36.946,51.964Z"
        transform="translate(22.997 13.929)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Trazado_119"
        data-name="Trazado 119"
        d="M40.245,52.713a30.736,30.736,0,0,0-5.538,14.749c-.634,7.6,1.871,14.734,4.052,22.165a74.97,74.97,0,0,1,2.184,8.94c2.529,15.07-3.825,28.522,3.5,40.749,6.474,10.806,12.79,12.813,14.073,26.029,1,10.32-1.26,19.315,6.506,28.263,9.306,10.716,26.082,14.172,40.658,16.465l2.43.376c17.7,2.742,28.592,4.724,46.749,10.338,9.8,3.033,18.649,4.871,25.579,5.038s13.751-.971,19.668-6.924c7.057-7.1,8.848-19.28,10.61-28.747.2-1.06.364-2.108.56-3.138,1.476-7.718,2.266-13.8,4.967-21.158,5.075-13.818,17.571-29.465,28.961-44.023,8.751-11.186,18.671-22.4,20.934-36.317,2.257-14.2-6.913-30.863-21.9-33.859-8.932-1.786-17.526.712-25.48,5.031-13.05,7.093-25.828,17.257-41.272,15.607a31.21,31.21,0,0,1-7.614-1.791c-14.449-5.384-22.117-20.461-33.111-30.8-8.206-7.716-18.454-13.54-29.883-15.406C90.4,21.629,73.531,27.085,59.7,35.29,52.131,39.784,45.056,45.632,40.245,52.713Z"
        transform="translate(25.624 15.871)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Trazado_120"
        data-name="Trazado 120"
        d="M43.585,53.46a31.639,31.639,0,0,0-5.213,13.373c-.921,6.877.635,13.549,2.292,20.414.656,2.682,1.321,5.4,1.838,8.168,2.536,13.627.268,26.179,8,36.818,7.841,10.791,14.127,12.112,14.726,26.065.459,10.658-3.054,19.879,4.382,29.654,8.264,10.86,24.006,15.724,38.143,17.252q1.185.128,2.358.229c17.22,1.528,29.568-.579,46.962,4.765,9.289,2.858,17.338,6.136,24.3,7.887s14.59,2.3,21.094-2.647c7.776-5.917,9.595-17.9,9.873-27.294.031-1.072.024-2.127.03-3.17.03-7.85-.98-14.493.36-22.163,2.548-14.577,14.167-29.846,25.328-43.532,9.363-11.479,20.079-22.89,22.632-37.362,2.551-14.66-7.233-32.406-23.043-34.8-8.718-1.318-16.729,1.568-24.178,6.048-12.23,7.362-23.941,17.739-38.8,16.6a29.7,29.7,0,0,1-7.331-1.507c-13.8-4.707-22.276-18.586-33.386-27.968A57.3,57.3,0,0,0,105.44,27.07c-15.406-2.231-31.185,2.359-43.972,9.994C54.414,41.279,48,46.785,43.585,53.46Z"
        transform="translate(28.209 17.813)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Trazado_121"
        data-name="Trazado 121"
        d="M47.014,54.208a33.916,33.916,0,0,0-4.887,12c-1.194,6.156-.6,12.361.533,18.662.446,2.448.968,4.911,1.488,7.394,2.546,12.186,4.386,23.7,12.5,32.889,9.353,10.6,15.479,11.418,15.38,26.1-.075,11-4.776,20.419,2.257,31.045,7.284,11,21.929,17.277,35.628,18.041q1.149.065,2.289.084c16.736.313,30.546-5.884,47.172-.809,8.779,2.682,16.028,7.4,23.021,10.738s15.436,5.585,22.518,1.629c8.505-4.722,10.322-16.51,9.141-25.843-.138-1.078-.317-2.144-.5-3.2-1.42-7.979-4.24-15.174-4.245-23.169-.007-15.318,10.775-30.209,21.7-43.038,10-11.749,21.487-23.384,24.333-38.407C258.174,63.2,247.764,44.3,231.14,42.584c-8.516-.876-15.935,2.421-22.88,7.064-11.407,7.631-22.055,18.22-36.316,17.587a28.267,28.267,0,0,1-7.047-1.222c-13.151-4.03-22.422-16.73-33.663-25.139a57.375,57.375,0,0,0-27.145-11.035,64.837,64.837,0,0,0-40.764,9A49.253,49.253,0,0,0,47.014,54.208Z"
        transform="translate(30.706 19.754)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Trazado_122"
        data-name="Trazado 122"
        d="M44.853,82.488a57.6,57.6,0,0,0,18.133,35.581C73.978,128.237,79.833,128.8,79.02,144.2c-.6,11.357-6.417,20.946.134,32.439,6.359,11.158,19.851,18.829,33.111,18.829,17.086,0,33.023-11.47,49.6-6.446,8.267,2.5,14.715,8.664,21.741,13.587s16.268,8.855,23.941,5.906c9.219-3.545,11.034-15.092,8.406-24.39s-8.368-17.971-9.886-27.408c-2.581-16.03,7.393-30.553,18.062-42.545s22.9-23.877,26.035-39.452S242.26,39,224.833,38.047c-22.02-1.2-33.435,26.86-55.417,26.657-15.808-.145-27.339-14.557-40.7-23.246-25.429-16.54-63.659-9.93-78.072,13.5A43.372,43.372,0,0,0,44.853,82.488Z"
        transform="translate(33.002 21.695)"
        fill="none"
        stroke="#59b1de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

export { wave_svg };

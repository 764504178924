import "./sectors-resume.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import { Image, Photo } from "../../types/shared";
import SectorResume, { LinkSanity } from "./SectorResume/SectorResume";

const enum PositionSlider {
  LOGISTICS = 1,
  FOOD_SERVICE = 2,
}

const SectorsResume: () => JSX.Element = () => {
  const sliderResumes = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [positionSlider, setPositionSlider] = useState<PositionSlider>(1);
  const totalSlides = 2;

  const {
    allSanityTemplateLogisticsServices: logisticsServices,
    sanityPageLogistics: logistic,
    sanityPageFoodServices: foodServices,
    allSanityTemplateFoodServicesServices: foodServiceServices,
    sanityPageOurProducts: ourProducts,
  }: Queries.ServicesResumeQuery = useStaticQuery(graphql`
    query ServicesResume {
      sanityPageLogistics {
        seo {
          thumbnail {
            asset {
              _id
              gatsbyImageData
            }
          }
        }
      }
      sanityPageFoodServices {
        seo {
          thumbnail {
            asset {
              _id
              gatsbyImageData
            }
          }
        }
      }
      allSanityTemplateLogisticsServices {
        nodes {
          slug {
            current
          }
          hero {
            title
          }
        }
      }
      allSanityTemplateFoodServicesServices {
        nodes {
          slug {
            current
          }
          hero {
            title
          }
        }
      }
      sanityPageOurProducts {
        slug {
          current
        }
        hero {
          title
        }
      }
    }
  `);

  const foodServicesServicesLinksList: LinkSanity[] = [];
  foodServiceServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.slug?.current) {
      return;
    }
    foodServicesServicesLinksList.push({
      slug: service.slug,
      name: service.hero.title,
    });
  });
  if (ourProducts?.hero?.title && ourProducts?.slug?.current) {
    foodServicesServicesLinksList.push({
      slug: ourProducts.slug,
      name: ourProducts.hero.title,
    });
  }
  foodServicesServicesLinksList.push({
    slug: { current: `/app-usicom` },
    name: `APP USICOM`,
  });

  const logisticsLinksList: LinkSanity[] = [];
  logisticsServices.nodes.forEach((service) => {
    logisticsLinksList.push({
      name: service.hero?.title,
      slug: service.slug,
    });
  });

  const imageLogistic: Photo = {
    image: logistic?.seo?.thumbnail as Image,
    alt: "Portada de logística",
  };
  const imageFoodService: Photo = {
    image: foodServices?.seo?.thumbnail as Image,
    alt: "Portada Food service",
  };

  function handlerIntersect(entries, _observer) {
    entries.forEach((entry, _index) => {
      if (entry.isIntersecting) {
        const idHtml: string = entries[_index].target.id;
        const splitIdHtml = idHtml.split("slider-resume__");
        const index: number = parseInt(splitIdHtml[1]);
        setPositionSlider(index);
      }
    });
  }

  function createObserver() {
    const options = {
      root: sliderResumes.current,
      rootMargin: "0px",
      delay: 500,
      threshold: 1,
    };
    const observer = new IntersectionObserver(handlerIntersect, options);
    if (document.getElementById(`slider-resume__1`)) {
      observer.observe(document.getElementById(`slider-resume__1`) as HTMLElement);
    }
    if (document.getElementById(`slider-resume__2`)) {
      observer.observe(document.getElementById(`slider-resume__2`) as HTMLElement);
    }
  }

  useEffect(() => {
    sliderResumes.current.style.scrollSnapType = "unset";
    const elementSelected = sliderResumes.current.children[positionSlider - 1] as HTMLElement;
    sliderResumes.current.scrollLeft = elementSelected.offsetLeft;
  }, [positionSlider]);

  const prev = () => {
    if (positionSlider < 2) {
      setPositionSlider(totalSlides);
    } else {
      setPositionSlider(positionSlider - 1);
    }
  };

  const next = () => {
    if (positionSlider >= totalSlides) {
      setPositionSlider(1);
    } else {
      setPositionSlider(positionSlider + 1);
    }
  };

  function setMandatoryX() {
    sliderResumes.current.style.scrollSnapType = "x mandatory";
  }

  function setPositionInOne() {
    setPositionSlider(1);
  }

  useLayoutEffect(() => {
    if (!sliderResumes.current) return;
    createObserver();
    sliderResumes.current.addEventListener("touchmove", setMandatoryX, { passive: true });
    window.addEventListener("resize", setPositionInOne);
    return () => {
      if (sliderResumes.current) {
        sliderResumes.current.removeEventListener("touchmove", setMandatoryX);
      }
      window.removeEventListener("resize", setPositionInOne);
    };
  }, []);

  return (
    <section className={"o-sectors-resume"}>
      <div
        className="c-sectors-resume"
        ref={sliderResumes}>
        <SectorResume
          index={1}
          title={"Logística Frío"}
          photo={imageLogistic}
          linkServicesList={logisticsLinksList}
          slug={"logistica"}
          is_blueBackground={true}
        />
        <SectorResume
          index={2}
          title={"Distribución Comercial"}
          photo={imageFoodService}
          linkServicesList={foodServicesServicesLinksList}
          slug={"distribucion-comercial"}
        />
      </div>
      <div
        className={`m-sectors-resume__controls ${
          positionSlider === PositionSlider.FOOD_SERVICE
            ? "m-sectors-resume__controls--food-services"
            : "m-sectors-resume__controls--logistic"
        }`}>
        {/*<button*/}
        {/*  className={"m-button"}*/}
        {/*  onClick={prev}>*/}
        {/*  Logística Frío*/}
        {/*  {left_svg}*/}
        {/*</button>*/}
        <button
          className={`m-button ${positionSlider === PositionSlider.FOOD_SERVICE ? "" : "m-button--white"}`}
          onClick={positionSlider === PositionSlider.FOOD_SERVICE ? prev : next}>
          {positionSlider === PositionSlider.FOOD_SERVICE ? "Logística Frío" : "Food services"}
          {positionSlider === PositionSlider.FOOD_SERVICE ? right_svg : left_svg}
        </button>
      </div>
    </section>
  );
};

export default SectorsResume;

import "./gallery-open.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { left_svg, right_svg } from "../../../assets/svg/controls_elements";
import { Photo } from "../../../types/shared";

type PropsGalleryOpen = PropsWithChildren<{ photosList: Photo[]; photoSelected: number }>;

const GalleryOpen: React.FC<PropsGalleryOpen> = (props: PropsGalleryOpen) => {
  const [photoSelected, setPhotoSelected] = useState<number>(props.photoSelected);

  const is_canNext = () => {
    return !(photoSelected > props.photosList.length - 2);
  };
  const is_canPrev = () => {
    return !(photoSelected < 1);
  };

  const next = (e) => {
    e.stopPropagation();
    if (!is_canNext()) return;
    setPhotoSelected(photoSelected + 1);
  };

  const prev = (e) => {
    e.stopPropagation();
    if (!is_canPrev()) return;
    setPhotoSelected(photoSelected - 1);
  };
  return (
    <section className={"o-gallery-open"}>
      <div className={"c-gallery-open"}>
        <button
          disabled={!is_canPrev()}
          onClick={(e) => prev(e)}
          aria-label={"Pasar a la foto anterior"}
          className={`m-control m-control--outline ${!is_canPrev() ? "m-control--disable" : ""} `}>
          {left_svg}
        </button>
        <SanityImage
          {...props.photosList[photoSelected].image}
          width={855}
          height={636}
          alt={props.photosList[photoSelected].alt ? props.photosList[photoSelected].alt : ""}
        />
        <button
          disabled={!is_canNext()}
          onClick={(e) => next(e)}
          aria-label={"Pasar a la foto siguiente"}
          className={`m-control m-control--outline ${!is_canNext() ? "m-control--disable" : ""} `}>
          {right_svg}
        </button>
      </div>
    </section>
  );
};

export default GalleryOpen;

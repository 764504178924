import "./goals.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import Queries from "../../gatsby-types";
import { getWidthScreen } from "../../utils/window";
import { wave_svg } from "./elements_goals";
import Goal, { GoalItem } from "./Goal/Goal";

const Goals: () => JSX.Element = () => {
  const { sanityPageHome } = useStaticQuery(graphql`
    query Goals {
      sanityPageHome {
        goals {
          title
          _rawResume
          goalsList {
            title
            icon {
              image {
                asset {
                  _id
                  gatsbyImageData
                }
              }
            }
            _rawResume
          }
        }
      }
    }
  `);
  const [is_firstLoad, setIsFirstLoad] = useState<boolean>(true);
  const sliderGoals = useRef() as React.MutableRefObject<HTMLDivElement>;
  const goals = sanityPageHome.goals as Queries.SanityGoals;
  const [positionSlider, setPositionSlider] = useState<number>(1);
  const totalSlides = goals.goalsList ? goals.goalsList.length : 0;

  function setMandatoryX() {
    sliderGoals.current.style.scrollSnapType = "x mandatory";
  }

  function setPositionInOne() {
    setPositionSlider(1);
  }

  function handlerIntersect(entries, _observer) {
    entries.forEach((entry, _index) => {
      if (entry.isIntersecting && getWidthScreen() < 1500) {
        const idHtml: string = entries[_index].target.id;
        const splitIdHtml = idHtml.split("slide-goal__");
        const index: number = parseInt(splitIdHtml[1]);
        setPositionSlider(index + 1);
      }
    });
  }

  function createObserver() {
    const options = {
      root: sliderGoals.current,
      rootMargin: "0px",
      delay: 0,
      threshold: 1,
    };
    const observer = new IntersectionObserver(handlerIntersect, options);
    if (goals.goalsList) {
      goals.goalsList.forEach((goal, index) => {
        observer.observe(document.getElementById(`slide-goal__${index}`) as HTMLElement);
      });
    }
  }

  const prev = () => {
    if (positionSlider < 2) {
      setPositionSlider(totalSlides);
    } else {
      setPositionSlider(positionSlider - 1);
    }
  };
  const next = () => {
    if (positionSlider >= totalSlides) {
      setPositionSlider(1);
    } else {
      setPositionSlider(positionSlider + 1);
    }
  };

  useEffect(() => {
    if (is_firstLoad) {
      setIsFirstLoad(false);
      return;
    }
    sliderGoals.current.style.scrollSnapType = "unset";
    const elementSelected = sliderGoals.current.children[positionSlider - 1] as HTMLElement;
    const is_inViewPort =
      elementSelected.offsetWidth * (positionSlider - 1) >= sliderGoals.current.scrollLeft &&
      elementSelected.offsetWidth * (positionSlider - 1) + elementSelected.offsetWidth <=
        sliderGoals.current.clientWidth;
    if (!is_inViewPort) {
      sliderGoals.current.scrollLeft = elementSelected.offsetWidth * (positionSlider - 1);
    }
  }, [positionSlider]);

  useEffect(() => {
    createObserver();
    sliderGoals.current.addEventListener("touchmove", setMandatoryX, { passive: true });
    window.addEventListener("resize", setPositionInOne);
    return () => {
      if (sliderGoals.current) {
        sliderGoals.current.removeEventListener("touchmove", setMandatoryX);
      }
      window.removeEventListener("resize", setPositionInOne);
    };
  }, []);

  return (
    <section className={"o-goals"}>
      <div className="c-goals">
        <div className="m-goals__info">
          <h2>{goals.title}</h2>
          <BlockContent blocks={goals._rawResume} />
        </div>
        <div className="m-goals__elements">
          <div className="m-goals__logo">
            <StaticImage
              src={"../../assets/images/favicom.png"}
              alt={"Logotipo Grupo Usieto"}
            />
          </div>
          <div>{wave_svg}</div>
        </div>
        <div className="m-goals__right">
          <div
            className="m-goals__items"
            ref={sliderGoals}>
            {goals.goalsList &&
              goals.goalsList.map((goal: Queries.SanityGoal, index: number) => {
                const goalItem = goal as GoalItem;
                if (goal) {
                  return (
                    <Goal
                      key={index}
                      index={index}
                      goal={goalItem}
                      is_active={positionSlider - 1 === index}
                      setPositionSlider={setPositionSlider}
                    />
                  );
                }
              })}
          </div>
          <div className="m-goals__controls">
            <button
              className={`m-control ${positionSlider === 1 ? "m-control--disable" : ""}`}
              disabled={positionSlider === 1}
              aria-label={"Seleccionar anterior punto fuerte"}
              onClick={prev}>
              {left_svg}
            </button>
            <button
              aria-label={"Seleccionar siguiente punto fuerte"}
              className={`m-control ${positionSlider === totalSlides ? "m-control--disable" : ""}`}
              disabled={positionSlider === totalSlides}
              onClick={next}>
              {right_svg}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Goals;
